<template>
  <div class="activity-rules">
    <div class="container" v-html="text">
      
    </div>
  </div>
</template>
<script>
export default{
  name:'ActivityRules',
  data() {
    return {
      userAgent:'',
      text:`<h2>"Gift for Invitation" - Activity Rules</h2>
          <p>1. Requirement for Participation</p>
          <p>1.1 For Inviters</p>
          <p>As an inviter, you should already own an account on the TravelMate App, TravelMate Data Mall, or TravelMate Wechat Mini Program.</p>
          <p>1.2 For Invitees</p>
          <p>As an invitee, the mobile phone number or email address you used for registration should be NEW. It means the sign-up information should not yet be used to register from TravelMate App, TravelMate Data Mall, or TravelMate Wechat Mini Program.</p>
          <p>2. Rules for "Gift for Invitation"</p>
          <p>2.1 Inviters - Get your Exclusive Invitation Code to share, from the "Invite Now" - "Gift for Invitation" page of the TravelMate App or TravelMate Wechat Mini Program. Click the "Share Now" button to share with friends. (the "Share Now"button will automatically create Recommendation Content which includes "a link to TravelMate Registration" and "Inviter's Exclusive Invitation Code")</p>
          <p>2.2 Invitees - Click the link from the inviter's share content, and apply the "Inviter's Exclusive Invitation Code" when registering. </p>
          <p>3.Reward for "Gift for Invitation"</p>
          <p>3.1 Inviters - The "Gift for Invitation" reward coupons will automatically be released to the inviter's account, once the invitees successfully sign in by entering your "Inviter's Exclusive Invitation Code". The reward can be details checked in [Member Center] - [Coupon], and available for TravelMate orders.</p>
          <p>3.2 Invitees - The sign-up coupon rewards will automatically be released to the invitee's account, once you have successfully signed in by entering the "Inviter's Exclusive Invitation Code". The rewards can be details checked in [Member Center] - [Coupon], and available for TravelMate orders.</p>
          <p>3.3 All of the coupons are non-transferable and non-redeemable. </p>
          <p>4. Activity Statement</p>
          <p>4.1  Participation in this activity indicates that you accept and agree to be bound by these terms and conditions, and confirm that you have read and understood them. You must comply with the relevant rules of the activity, and must not use any means including but not limited to cheating, system disruption, or suspicious or fraudulent purchases to obtain rewards. If such behavior is detected, TravelMate reserves the right to withhold rewards, freeze all rewards obtained through placing orders, and pursue legal responsibility in accordance with the severity of the circumstances.</p>
          <p>4.2 TravelMate will not be responsible for any errors or delays in participation information caused by network transmission or user hardware.</p>
          <p>4.3 TravelMate reserves the right to modify these rules, suspend, or terminate the activity at any time in accordance with relevant laws and regulations, company operating conditions, and business strategies.</p>
          <p>4.4 If you have any other questions, please contact customer service.</p>
          `
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      this.$http.get(this.APIURL.PUBLIC.INVITERULE,{type:this.$route.query.type}).then(res=>{
        if(res.data.rule) this.text = res.data.rule;
      })
    }
  },
}
</script>
<style lang="scss" scoped>
  .activity-rules{
    padding: 10px;
  }
</style>